@import "_app.scss";

html {
  min-height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  float: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #666;
}

/* ------------------------------------------------------------------

=default fonts

 -------------------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  font-family: arial, sans-serif;
  font-weight: lighter;
}

p {
  margin: 0 0 20px 0;
  line-height: 20px;
}

/* ------------------------------------------------------------------

=List styles

-------------------------------------------------------------------*/

ul, ol {
  margin: 0 0 20px 25px;
}

ul {
  ul, ol {
    margin-top: 10px;
  }
}

ol {
  ol, ul {
    margin-top: 10px;
  }
}

/* ------------------------------------------------------------------

=Link styles

-------------------------------------------------------------------*/

a {
  cursor: pointer;
  text-decoration: none;
  color: #0088CC;

  &:link, &:visited {
    cursor: pointer;
    text-decoration: none;
    color: #0088CC;
  }

  &:hover, &:active {
    text-decoration: none;
  }
}

/* ------------------------------------------------------------------

=table styles

-------------------------------------------------------------------*/

table {
  th, td {
    vertical-align: top;
    text-align: left;
  }
}

/* ------------------------------------------------------------------

=form styles

-------------------------------------------------------------------*/

form {
  width: 100%;
  float: left;
}

.inputWrapper {
  width: 100%;
  float: left;
  margin: 0 0 10px 0;
  position: relative;

  label {
    height: 24px;
    float: left;
    overflow: hidden;
    margin: 0 10px 0 0;
    line-height: 24px;
    position: relative;
    text-align: right;
    cursor: text;

    em {
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      color: #c00;
    }
  }

  input {
    &[type="text"], &[type="email"], &[type="password"] {
      height: 22px;
      padding: 7px;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-shadow: inset 0 2px 3px 0 #e0e0e0;
      float: left;
      line-height: 22px;
      font-family: arial, sans-serif;
      font-size: 13px;
      color: #666;
      background: #fff;
    }
  }

  textarea, select {
    height: 22px;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: inset 0 2px 3px 0 #e0e0e0;
    float: left;
    line-height: 22px;
    font-family: arial, sans-serif;
    font-size: 13px;
    color: #666;
    background: #fff;
  }

  input {
    &[type="radio"], &[type="checkbox"] {
      cursor: pointer;
    }
  }

  select {
    cursor: pointer;
    height: auto;
  }
}

/* =watermark */

.watermark label {
  padding-left: 24px;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 3;
  text-align: left;

  [class^="icon-"] {
    width: 18px;
    height: 18px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    left: 0;
    background: image-url('global/icons/icons-form.png') no-repeat 0 0;
  }

  .icon-email {
    background-position: 0 -18px;
  }

  .icon-password, .icon-key {
    background-position: 0 -36px;
  }

  .icon-padlock {
    background-position: 0 -91px;
  }

  .icon-briefcase {
    background-position: 0 -73px;
  }

  .icon-pencil {
    background-position: 0 -109px;
  }
}

/**/

.noWatermark label {
  padding-left: 0 !important;
  position: static !important;
}

/* ------------------------------------------------------------------

=Generic classes

-------------------------------------------------------------------*/

.hide {
  display: none !important;
}

.clear {
  clear: both !important;
}

.clearfix:after, .centerIt:after, #heroBanner .loggedIn:after {
  content: '.';
  width: 100%;
  height: 0;
  display: block;
  line-height: 0;
  clear: both;
  visibility: hidden;
}

.clearLeft {
  clear: left !important;
}

.clearRight {
  clear: right !important;
}

/**/

.positionOff {
  position: absolute;
  top: -999999em !important;
}

/**/

.relative {
  position: relative !important;
}

/**/

.absolute {
  position: absolute !important;

  &.top {
    top: 0 !important;
  }

  &.right {
    right: 0 !important;
  }

  &.bottom {
    bottom: 0 !important;
  }

  &.left {
    left: 0 !important;
  }
}

/**/

.pull-left, .floatLeft {
  float: left !important;
}

.pull-right, .floatRight {
  float: right !important;
}

/**/

.noPadding {
  padding: 0 !important;
}

.noBorder {
  border: none !important;
}

.noMargin {
  margin: 0 !important;
}

.noBackground {
  background: none !important;
}

.noListStyle {
  list-style: none !important;
}

/**/

.align-center, .alignCenter {
  text-align: center !important;
}

.align-left, .alignLeft {
  text-align: left !important;
}

.align-right, .alignRight {
  text-align: right !important;
}

/**/

.centerIt {
  width: 100%;
  float: left;
  position: relative;

  .push {
    float: left;
    position: relative;
    left: 50%;

    > * {
      position: relative;
      left: -50%;
    }
  }
}

/**/

.ucwords, .uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalise, .capitalize {
  text-transform: capitalize !important;
}

/**/

.underline {
  text-decoration: underline !important;
}

.strikethrough {
  text-decoration: line-through !important;
}

/**/

.cursor-pointer, .js-trigger-popup {
  cursor: pointer !important;
}

/**/

ul.none, ol.none, [class*="menu-"].none {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* ------------------------------------------------------------------

=Page structure

-------------------------------------------------------------------*/

.wrapperContainer {
  width: 980px;
  height: 100%;
  padding: 0;
  clear: both;
  margin: 0 auto;
  position: relative;
}

/* =header */

#header {
  width: 100%;
  padding: 0;
  float: left;
  margin: 0;
  position: relative;
  z-index: 6;
}

#heroBanner {
  width: 100%;
  float: left;
  margin: 0;
  position: relative;
  z-index: 5;
}

#content {
  width: 100%;
  float: left;
  overflow-x: hidden;
  position: relative;
  z-index: 4;
}

#footer {
  width: 100%;
  float: left;
  position: relative;
  z-index: 3;

  p {
    margin: 0;
  }
}

/*=========================

=Lightbox

=========================*/

.lb-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  opacity: 0.75;
  filter: alpha(opacity = 75);
  background: #000 repeat;
}

.lightbox {
  padding: 20px;
  border-radius: 5px;
  display: none;
  box-shadow: 0 0 3px 2px #262626;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1101;
  background: #fff;
  width: 600px;

  .lb-close {
    width: 35px;
    height: 37px;
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    top: -14px;
    right: -16px;
    z-index: 1;
    text-indent: -9999em;
    background: image-url('global/lb-close.png');
  }

  .lb-header {
    width: 100%;
    float: left;
    margin: 0 0 20px;
    position: relative;
    z-index: 2;
  }

  .lbIntro {
    padding: 0 0 20px;
    border-bottom: 1px solid #e2e2e2;
    margin: 0;

    .title {
      font-size: 40px;
    }

    .subtitle {
      font-size: 20px;
    }
  }

  .lb-content {
    width: 100%;
    float: left;
    z-index: 2;
  }

  .lb-footer {
    width: 100%;
    float: left;

    p {
      margin: 0;
    }
  }
}

/* =menu */

.menu {
  margin: 0;
  list-style: none;

  li {
    position: relative;

    a {
      position: relative;
      z-index: 2;
    }

    ul {
      margin: 0;
      list-style: none;
      z-index: 1;

      a {
        padding: 8px 16px;
      }
    }
  }
}

/* =header */

#header .menu li {
  float: left;

  a {
    float: left;
  }
}

/* =footer */

#footer {
  padding: 24px 0 0;

  .menu {
    float: left;

    &:first-child {
      margin: 0;
    }

    .link-title {
      padding: 0 0 5px;
      text-transform: uppercase;
      font-size: 16px;
      color: #666262;
    }

    a {
      padding: 3px 0;
      display: block;
      text-transform: capitalize;
      font-size: 13px;
      color: #848484;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }

  .socialIcons {
    padding: 7px 0;
    float: right;
    margin: 0;
    list-style: none;

    li {
      float: left;
      margin: 0 0 0 5px;

      a {
        width: 16px;
        height: 16px;
        float: left;
        overflow: hidden;
        text-indent: -9999em;
        background: image-url('global/icons/icons-enhance.png') no-repeat -22px -9999em;
      }
    }

    .facebook a {
      background-position: -22px 0;
    }

    .twitter a {
      background-position: -22px -16px;
    }
  }
}

#baseFooter {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  float: left;
  margin: 20px 0 0;

  .menu {
    li {
      float: left;
      margin: 0 25px 0 0;
    }

    a {
      height: 30px;
      padding: 0;
      overflow: hidden;
      line-height: 30px;
    }
  }
}

#copyright {
  height: 30px;
  float: right;
  overflow: hidden;
  line-height: 30px;
  font-size: 13px;
  color: #848484;
}

/* =buttons */

.btn {
  border: none;
  float: left;

  * {
    border: none;
    float: left;
    font-weight: normal;
    white-space: nowrap;
  }

  &:hover, &:focus {
    text-decoration: none;
  }

  &.shadow {
    box-shadow: 0 0 3px 0 #333;
  }

  &.grey {
    height: 39px;
    padding: 0;
    border: 1px solid #bebebe;
    border-radius: 5px;
    margin: 0;
    background: image-url('global/buttons/btn-generic.png') repeat-x 0 0;

    * {
      height: 39px;
      padding: 0 20px;
      line-height: 39px;
      text-shadow: 0 1px #ededed;
      font-size: 23px;
      color: #868686;
      background: image-url('global/buttons/btn-generic.png') no-repeat right 0;
    }

    &:hover, &:focus {
      background-position: 0 -39px;
    }

    &:hover *, &:focus * {
      color: #a1a1a1;
      background-position: right -39px;
    }
  }

  input {
    float: none !important;
  }
}

/* =btn-shadow */

/**/

/**/

/* ticklist */

.ticklist {
  width: 100%;
  padding: 10px 0 0;
  float: left;
  margin: 0 0 25px;
  list-style: none;

  li {
    padding: 0 0 0 32px;
    margin: 0 0 14px;
    line-height: 26px;
    position: relative;
    font-size: 18px;
    color: #626262;

    .icon {
      width: 22px;
      height: 18px;
      overflow: hidden;
      position: absolute;
      top: 3px;
      left: 0;
      background: image-url('global/icons/icons-enhance.png') no-repeat 0 0;
    }
  }
}

/* ticks and cross(es) */

[class^="icon-tick-"] {
  width: 28px;
  height: 22px;
  display: block;
  overflow: hidden;
  text-indent: -9999em;
  background: image-url('global/icons/ticks.png') no-repeat 0 -9999em;
}

.icon-tick-yellow {
  background-position: 0 0;
}

.icon-tick-red {
  background-position: 0 -22px;
}

.icon-tick-lightBlue {
  background-position: 0 -44px;
}

.icon-tick-darkBlue {
  background-position: 0 -66px;
}

.icon-tick-blue {
  background-position: 0 -88px;
}

.icon-tick-cross {
  background-position: 0 -110px;
}

.icon-tick-lightGreen {
  background-position: 0 -132px;
}

.icon-tick-green {
  background-position: 0 -154px;
}

.icon-tick-grey-small {
  background-position: 0 -176px;
}

.icon-tick-red-small {
  background-position: 0 -198px;
}

/* icon-global */

[class^="iconGlobal-32-"] {
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  text-indent: -9999em;
  background: image-url('global/icons/icons-global-enhance.png') no-repeat 0 -9999em;
}

.iconGlobal-32-tick {
  background-position: 0 0;
}

.iconGlobal-32-storage {
  background-position: 0 -32px;
}

.iconGlobal-32-support {
  background-position: 0 -64px;
}

.iconGlobal-32-padlock {
  background-position: 0 -96px;
}

.iconGlobal-32-files {
  background-position: 0 -128px;
}

.iconGlobal-32-cog {
  background-position: 0 -160px;
}

.iconGlobal-32-star {
  background-position: 0 -192px;
}

.iconGlobal-32-money {
  background-position: 0 -224px;
}

.iconGlobal-32-addUser {
  background-position: 0 -256px;
}

.iconGlobal-32-briefcase {
  background-position: 0 -288px;
}

.iconGlobal-32-wall {
  background-position: 0 -320px;
}

.iconGlobal-32-globalFile {
  background-position: 0 -352px;
}

.iconGlobal-32-music {
  background-position: 0 -384px;
}

.iconGlobal-32-folderLandscape {
  background-position: 0 -416px;
}

.iconGlobal-32-sync {
  background-position: 0 -448px;
}

.iconGlobal-32-editFile {
  background-position: 0 -480px;
}

.iconGlobal-32-monitor {
  background-position: 0 -512px;
}

.iconGlobal-32-picture {
  background-position: 0 -544px;
}

.iconGlobal-32-colours {
  background-position: 0 -576px;
}

.iconGlobal-32-globe {
  background-position: 0 -608px;
}

.iconGlobal-32-colourPalette {
  background-position: 0 -640px;
}

.iconGlobal-32-tag {
  background-position: 0 -672px;
}

.iconGlobal-32-fileChart {
  background-position: 0 -704px;
}

.iconGlobal-32-browser {
  background-position: 0 -736px;
}

.iconGlobal-32-switch {
  background-position: 0 -768px;
}

.iconGlobal-32-shareFile {
  background-position: 0 -800px;
}

.iconGlobal-32-clipboard {
  background-position: 0 -832px;
}

.iconGlobal-32-speechBubble {
  background-position: 0 -864px;
}

.iconGlobal-32-mobile {
  background-position: 0 -896px;
}

.iconGlobal-32-folderPortrait {
  background-position: 0 -928px;
}

.iconGlobal-32-magnify {
  background-position: 0 -960px;
}

.iconGlobal-32-arrowUp {
  background-position: 0 -992px;
}

.iconGlobal-32-map {
  background-position: 0 -1024px;
}

.iconGlobal-32-pencil {
  background-position: 0 -1056px;
}

.iconGlobal-32-wallet {
  background-position: 0 -1088px;
}

.iconGlobal-32-user {
  background-position: 0 -1120px;
}

.iconGlobal-32-users {
  background-position: 0 -1152px;
}

/* iconGlobal-20 */

[class^="iconGlobal-20-"] {
  width: 20px;
  height: 20px;
  display: block;
  overflow: hidden;
  text-indent: -9999em;
  background: image-url('global/icons/icons-20px.png') no-repeat 0 -9999em;
}

.iconGlobal-20-windowsBlue {
  background-position: 0 0;
}

.iconGlobal-20-kindle {
  background-position: 0 -20px;
}

.iconGlobal-20-linux {
  background-position: 0 -40px;
}

.iconGlobal-20-infinity {
  background-position: 0 -60px;
}

.iconGlobal-20-hdd {
  background-position: 0 -80px;
}

.iconGlobal-20-storage {
  background-position: 0 -100px;
}

.iconGlobal-20-fileShare {
  background-position: 0 -120px;
}

.iconGlobal-20-fax {
  background-position: 0 -140px;
}

.iconGlobal-20-phone {
  background-position: 0 -160px;
}

.iconGlobal-20-blackberry {
  background-position: 0 -180px;
}

.iconGlobal-20-android {
  background-position: 0 -200px;
}

.iconGlobal-20-apple {
  background-position: 0 -220px;
}

.iconGlobal-20-windows {
  background-position: 0 -240px;
}

.iconGlobal-20-noEntry {
  background-position: 0 -260px;
}

.iconGlobal-20-files {
  background-position: 0 -280px;
}

.iconGlobal-20-switch {
  background-position: 0 -300px;
}

.iconGlobal-20-music {
  background-position: 0 -320px;
}

.iconGlobal-20-film {
  background-position: 0 -340px;
}

.iconGlobal-20-pc {
  background-position: 0 -360px;
}

.iconGlobal-20-arrowUp {
  background-position: 0 -380px;
}

.iconGlobal-20-upload {
  background-position: 0 -400px;
}

.iconGlobal-20-cursor {
  background-position: 0 -420px;
}

.iconGlobal-20-padlock {
  background-position: 0 -440px;
}

.iconGlobal-20-monitor {
  background-position: 0 -460px;
}

.iconGlobal-20-money {
  background-position: 0 -480px;
}

.iconGlobal-20-briefcase {
  background-position: 0 -500px;
}

.iconGlobal-20-user {
  background-position: 0 -520px;
}

.iconGlobal-20-fileUpload {
  background-position: 0 -540px;
}

.iconGlobal-20-folderUpload {
  background-position: 0 -560px;
}

.iconGlobal-20-fileSync {
  background-position: 0 -580px;
}

.iconGlobal-20-fileNoAccess {
  background-position: 0 -600px;
}

.iconGlobal-20-fileAccess {
  background-position: 0 -620px;
}

.iconGlobal-20-editFolder {
  background-position: 0 -640px;
}

.iconGlobal-20-folderNoAccess {
  background-position: 0 -660px;
}

.iconGlobal-20-folderAccess {
  background-position: 0 -680px;
}

.iconGlobal-20-calendarTick {
  background-position: 0 -700px;
}

.iconGlobal-20-calendar {
  background-position: 0 -720px;
}

.iconGlobal-20-calendarPlay {
  background-position: 0 -740px;
}

.iconGlobal-20-calendarAdd {
  background-position: 0 -760px;
}

.iconGlobal-20-remove {
  background-position: 0 -780px;
}

.iconGlobal-20-key {
  background-position: 0 -800px;
}

.iconGlobal-20-padlock2 {
  background-position: 0 -820px;
}

.iconGlobal-20-exclamation {
  background-position: 0 -840px;
}

.iconGlobal-20-arrowDown {
  background-position: 0 -860px;
}

.iconGlobal-20-bin {
  background-position: 0 -880px;
}

.iconGlobal-20-download {
  background-position: 0 -900px;
}

.iconGlobal-20-battery {
  background-position: 0 -920px;
}

.iconGlobal-20-storageUpload {
  background-position: 0 -940px;
}

.iconGlobal-20-fileAccessLeft {
  background-position: 0 -960px;
}

.iconGlobal-20-addComputer {
  background-position: 0 -980px;
}

.iconGlobal-20-tablet {
  background-position: 0 -1000px;
}

.iconGlobal-20-mobilePhone {
  background-position: 0 -1020px;
}

.iconGlobal-20-padlockWhite {
  background-position: 0 -1040px;
}

.iconGlobal-20-cd {
  background-position: 0 -1080px;
}

.iconGlobal-20-pictures {
  background-position: 0 -1100px;
}

.iconGlobal-20-file {
  background-position: 0 -1120px;
}

.iconGlobal-20-folderTick {
  background-position: 0 -1140px;
}

.iconGlobal-20-support {
  background-position: 0 -1160px;
}

.iconGlobal-20-receipts {
  background-position: 0 -1180px;
}

.iconGlobal-20-link {
  background-position: 0 -1200px;
}

.iconGlobal-20-padlockGrey {
  background-position: 0 -1220px;
}

.iconGlobal-20-envelope {
  background-position: 0 -1240px;
}

.iconGlobal-20-datacentre {
  background-position: 0 -1260px;
}

.iconGlobal-20-globe {
  background-position: 0 -1280px;
}

.iconGlobal-20-port {
  background-position: 0 -1300px;
}

.iconGlobal-20-255 {
  background-position: 0 -1320px;
}

.iconGlobal-20-minusGrey {
  background-position: 0 -1340px;
}

.iconGlobal-20-plusGrey {
  background-position: 0 -1360px;
}

.iconGlobal-20-cog {
  background-position: 0 -1380px;
}

.iconGlobal-20-placeTag {
  background-position: 0 -1400px;
}

.iconGlobal-20-calendar2 {
  background-position: 0 -1420px;
}

.iconGlobal-20-syncFiles {
  background-position: 0 -1440px;
}

.iconGlobal-20-receipt {
  background-position: 0 -1460px;
}

.iconGlobal-20-arrowLeftBlue {
  background-position: 0 -1480px;
}

.iconGlobal-20-cash {
  background-position: 0 -1500px;
}

.iconGlobal-20-file2 {
  background-position: 0 -1520px;
}

.iconGlobal-20-twitter {
  background-position: 0 -1540px;
}

.iconGlobal-20-openFolder {
  background-position: 0 -1560px;
}

.iconGlobal-20-people {
  background-position: 0 -1580px;
}

.iconGlobal-20-addressBook {
  background-position: 0 -1600px;
}

.iconGlobal-20-piggyBank {
  background-position: 0 -1620px;
}

.iconGlobal-20-contactDetails {
  background-position: 0 -1640px;
}

.iconGlobal-20-user2 {
  background-position: 0 -1660px;
}

.iconGlobal-20-question {
  background-position: 0 -1680px;
}

.iconGlobal-20-envelope2 {
  background-position: 0 -1700px;
}

.iconGlobal-20-versioning {
  background-position: 0 -1720px;
}

.iconGlobal-20-directory {
  background-position: 0 -1740px;
}

.iconGlobal-20-home {
  background-position: 0 -1760px;
}

.iconGlobal-20-star {
  background-position: 0 -1780px;
}

.iconGlobal-20-add {
  background-position: 0 -1800px;
}

.iconGlobal-20-cross {
  background-position: 0 -1820px;
}

.iconGlobal-20-pencil {
  background-position: 0 -1840px;
}

.iconGlobal-20-userGrey {
  background-position: 0 -1860px;
}

.iconGlobal-20-arrowRightGrey {
  background-position: 0 -1880px;
}

.iconGlobal-20-monitorGrey {
  background-position: 0 -1900px;
}

.iconGlobal-20-greyFolder {
  background-position: 0 -1920px;
}

.iconGlobal-20-arrowUpGrey {
  background-position: 0 -1940px;
}

.iconGlobal-20-tick {
  background-position: 0 -1960px;
}

.iconGlobal-20-chart {
  background-position: 0 -1980px;
}

.iconGlobal-20-gift {
  background-position: 0 -2000px;
}

.iconGlobal-20-clock {
  background-position: 0 -2020px;
}

.iconGlobal-20-facebook {
  background-position: 0 -2040px;
}

.iconGlobal-20-sync {
  background-position: 0 -2060px;
}

.iconGlobal-20-computer {
  background-position: 0 -2080px;
}

.iconGlobal-20-twitterBird {
  background-position: 0 -2100px;
}

/**/

[class^="icon-quotes-"] {
  width: 23px;
  height: 18px;
  display: block;
  overflow: hidden;
  background: image-url('global/icons/quotes.png') no-repeat 0 -9999em;
}

.icon-quotes-open {
  background-position: 0 0;
}

.icon-quotes-close {
  background-position: 0 -18px;
}

/**/

[class*="icon-social"] {
  width: 31px;
  height: 30px;
  display: block;
  overflow: hidden;
  text-indent: -9999em;
  background: image-url('global/icons/social.png') no-repeat 0 -9999em;
}

.icon-social-facebook {
  background-position: 0 0;
}

.icon-social-twitter {
  background-position: -30px 0;
}

/**/

[class*="flag-"] {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  text-indent: -9999em;
  background-repeat: no-repeat;
  background-position: 0 0;
}

/**/

[class*="flag-16-"] {
  width: 16px;
  height: 16px;
}

.flag-16-fr {
  background-image: image-url('global/flags/16/fr.png');
}

.flag-16-es {
  background-image: image-url('global/flags/16/es.png');
}

.flag-16-it {
  background-image: image-url('global/flags/16/it.png');
}

.flag-16-de {
  background-image: image-url('global/flags/16/de.png');
}

.flag-16-uk, .flag-16-gb, .flag-16-en {
  background-image: image-url('global/flags/16/uk.png');
}

.flag-16-pt {
  background-image: image-url('global/flags/16/pt.png');
}

.flag-16-eu {
  background-image: image-url('global/flags/16/eu.png');
}

.flag-16-ca {
  background-image: image-url('global/flags/16/ca.png');
}

.flag-16-nz {
  background-image: image-url('global/flags/16/nz.png');
}

.flag-16-us {
  background-image: image-url('global/flags/16/us.png');
}

.flag-16-au {
  background-image: image-url('global/flags/16/au.png');
}

.flag-16-ie {
  background-image: image-url('global/flags/16/ie.png');
}

/**/

[class*="icon-flag-"] {
  width: 32px;
  height: 25px;
  overflow: hidden;
  background: image-url('global/flags/32/icons-flags.png') no-repeat 0 -9999em;
}

.icon-flag-uk {
  background-position: 0 0;
}

.icon-flag-eu {
  background-position: 0 -25px;
}

.icon-flag-us {
  background-position: 0 -50px;
}

.icon-flag-au {
  background-position: 0 -75px;
}

.icon-flag-ca {
  background-position: 0 -100px;
}

.icon-flag-international {
  background-position: 0 -125px;
}

/* Message / Alerts */

[class*="message-"], .message {
  border: 1px solid;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-shadow: 0 1px #fff;
  padding: 15px;
  font-size: 14px;
  margin-bottom: 20px;
}

[class*="message-"] p {
  margin: 0;
}

.message-alert {
  border-color: #ebccd1;
  color: #a94442;
  background: #f2dede;
}

.message-success {
  border-color: #d6e9c6;
  color: #3c763d;
  background: #dff0d8;
}

/**/

.message {
  &.error {
    border-color: #EED3D7;
    color: #B94A48 !important;
    background: #F2DEDE;
  }

  &.success {
    border-color: #D6E9C6;
    color: #468847 !important;
    background: #DFF0D8;
  }

  &.info {
    border-color: #8FB2D2;
    color: #4E88B9 !important;
    background: #F4FAFF;

    .heading {
      padding: 0;
      margin: 0;
      font-weight: normal;
      color: inherit !important;
    }
  }
}

/**/

.logo-norton {
  width: 94px;
  height: 51px;
  display: inline-block;
  overflow: hidden;
  text-indent: -9999em;
  background: image-url('global/logos/logo-norton.png') no-repeat 0 0;
}

#optout-list-wrapper {
  text-align: center;
}

[class*="icon-32"] {
  width: 32px;
  height: 32px;
  display: inline-block;
  overflow: hidden;
  background: image-url('global/icons/icons-32.png') no-repeat 0 -9999em;
}

.icon-32-chat {
  background-position: 0 0;
}

.icon-32-email {
  background-position: 0 -32px;
}
